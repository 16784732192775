/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import "customize-bootstrap.scss";
@import "../node_modules/bootstrap/scss/bootstrap";
html {
  background-color: black;
  ::-webkit-scrollbar {
    display: none;
    font-family: "Oswald", sans-serif;
  }
}
@media (max-width: 990px) {
  .imgSize {
    width: 50px;
    height: 50px;
  }
  .marginMenu {
    margin-top: 60px;
  }
  .marginMenuProject {
    margin-top: 70px;
  }
  .bgNavBar {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
@media (min-width: 990px) {
  .imgSize {
    width: 100px;
    height: 100px;
  }
  .marginMenu {
    margin-top: 120px;
  }
  .marginMenuProject {
    margin-top: 0px;
  }
  .bgNavBar {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 50%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }
}
